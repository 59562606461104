import React from "react";
import "./HeroHome.scss";
import { Row } from "react-bootstrap";
import { CTAButton } from "../Button/Button";

const HeroHome = ({ title, subtitle, subtitleClassname, text, link }) => {
  return (
    <section className="heroHome">
      <Row className="row">
        <div className="inner">
          <div className="inner--half mobile-Banner">
            <h1 className="white">{title}</h1>
            <p className={subtitleClassname}>
              <big>{subtitle}</big>
            </p>
            {text ? (
              <p>
                <CTAButton text={text} link={link}></CTAButton>
              </p>
            ) : null}
          </div>
        </div>
      </Row>
    </section>
  );
};

export default HeroHome;
