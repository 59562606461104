import React from "react";
import { CTAButton } from "../Button/Button";
import "./OurStory.scss";
import { GatsbyImage } from "gatsby-plugin-image";

const OurStory = ({ reverse, body, image, bgColor, imgClass, altText }) => {
  return (
    <section>
      <table
        className="half-table"
        style={{ backgroundColor: bgColor ?? "#e31d3c" }}
      >
        <tbody>
          <tr className={reverse ? "reverse" : undefined}>
            <td>
              <div className="desc">
                {body ?? (
                  <div className="story-card">
                    <h2>BRINGING FAMILIES TOGETHER.</h2>
                    <p>
                      <big>
                        Nothing brings people together like sharing a wonderful
                        meal. Since 1948, families have trusted the
                        Sara&nbsp;Lee brand for quality, value, and just plain
                        deliciousness. The qualities that made our brand so
                        special continue to delight consumers
                      </big>
                    </p>
                    <p>
                      <CTAButton link="/about/our-story" text="Our Story" />
                    </p>
                  </div>
                )}
              </div>
            </td>
            <td>
              <GatsbyImage
                loading="lazy"
                image={image}
                className={imgClass ?? undefined}
                alt={altText}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default OurStory;
