import summerPizzaLarge from "../images/recipes/chicken/THUMBNAIL-summer-pizza.jpg";
import summerPizzaMedium from "../images/recipes/chicken/THUMBNAIL-summer-pizza-768x488.jpg";
import summerPizzaSmall from "../images/recipes/chicken/THUMBNAIL-summer-pizza-300x191.jpg";

import mvpBitesLarge from "../images/recipes/chicken/THUMBNAIL-mvp-bites.png";
import mvpBitesMedium from "../images/recipes/chicken/THUMBNAIL-mvp-bites-768x488.png";
import mvpBitesSmall from "../images/recipes/chicken/THUMBNAIL-mvp-bites-300x191.png";

import NaanWichLarge from "../images/recipes/chicken/THUMBNAIL-chicken-naanwich.png";

import BanhYumMiLarge from "../images/recipes/chicken/THUMBNAIL-chicken-bahn-mi.png";

import wontonLarge from "../images/recipes/chicken/THUMBNAIL-fried-chicken-wonton.png";

import summerPeppersLarge from "../images/recipes/chicken/THUMBNAIL-summer-peppers.jpg";

import cordonBlueHeader from "../images/recipes/chicken/RECIPE-headers-chicken-pizza.jpg";

import mvpHeader from "../images/recipes/chicken/RECIPE-headers-chicken-mvp.jpg";

import wontonHeader from "../images/recipes/chicken/RECIPE-headers-chicken-wonton.jpg";

import naanHeader from "../images/recipes/chicken/RECIPE-headers-chicken-naan.jpg";

import banhHeader from "../images/recipes/chicken/RECIPE-headers-chicken-banh-me.jpg";

import bellHeader from "../images/recipes/turkey/RECIPE-headers-turkey-peppers.jpg";

import { chickenProducts } from "./chicken/chickenProduct";

const saraleeOven = "Sara Lee® Oven Roasted Chicken Breast";

const chipotleChicken = "Sara Lee® Chipotle Seasoned Chicken Breast";

const chipChickenNormal = "Sara Lee® Chipotle Chicken Breast";

const canolaOil = "canola oil";
const OvenRoastedBreast = "Oven roasted Chicken Breast";
export const chickenRecipes = [
  {
    filterProtein: "chicken",
    filterCategory: ["enter", "grill", "lunch"],
    filterProductType: [OvenRoastedBreast],
    recipeName: "Grilled Chicken Cordon Bleu Pizza",
    heroImage: cordonBlueHeader,
    recipeLargeImage: summerPizzaLarge,
    recipeMediumImage: summerPizzaMedium,
    recipeSmallImage: summerPizzaSmall,
    recipeImageAlt: "Grilled Chicken Cordon Bleu Pizza",
    recipeDescription: `This one had us at Chicken Cordon Bleu—and then took it over
      the top! Enjoy a grilled pizza perfect for a weekend meal and
      packed with our Oven Roasted Chicken, bacon and three kinds of
      cheese.`,
    Link: "recipes/grilled-chicken-cordon-bleu-pizza",
    recipeIngredients: [
      {
        sentence: [
          { text: "3 oz. " },
          {
            text: saraleeOven,
            path: "/chicken/" + chickenProducts[0].path,
          },
        ],
      },
      { sentence: [{ text: "1 unbaked pizza crust, 12" }] },
      { sentence: [{ text: "1/4 cup " + canolaOil }] },
      { sentence: [{ text: "1/2 cup alfredo sauce, prepared" }] },
      { sentence: [{ text: "1/2 onion, thinly sliced" }] },
      { sentence: [{ text: "1/4 cup bell red pepper, julienned" }] },
      { sentence: [{ text: "1/2 cup bacon, crumbled" }] },
      { sentence: [{ text: "1/2 cup swiss cheese, shredded" }] },
      { sentence: [{ text: "1 tbsp parmesan cheese, grated" }] },
      { sentence: [{ text: "1/2 tsp parsley, chopped" }] },
      { sentence: [{ text: "1/2 tsp crushed red pepper" }] },
    ],
    recipeSteps: [
      "Pre-heat grill to medium heat",
      "Brush grill grates with canola oil, thoroughly",
      "Spread alfredo sauce on unbaked pizza crust, leaving 1/4 of edge clean of sauce",
      "Top the pizza with chicken, red onion, bell pepper, bacon, and all cheeses",
      "Place prepared pizza on grill and grill for 5 - 6 minutes",
      "Close the cover of the grill for half the time, and check to make sure the crust is not burning (if the crust is browning too quickly, turn off the grill and keep cover closed)",
      "Cook until cheese is melted",
      "Garnish with parsley and crushed pepper",
    ],
  },
  {
    filterProtein: "chicken",
    filterCategory: ["lunch"],
    filterProductType: [OvenRoastedBreast],
    recipeName: "Curried Chicken Naan-wich",
    recipeLargeImage: NaanWichLarge,
    heroImage: naanHeader,
    recipeMediumImage: "",
    recipeSmallImage: "",
    recipeImageAlt: "Curried Chicken Naan-wich",
    recipeDescription: `When is a sandwich unlike anything you've tried? 
    When it's a Naan-wich—made with deli style Oven Roasted Chicken Breast tucked 
    into naan bread and seasoned with Dijon mustard.`,
    Link: "recipes/curried-chicken-naan-wich-sandwich/",
    recipeIngredients: [
      {
        sentence: [
          { text: "5 slices " },
          {
            text: saraleeOven,
            path: "/chicken/" + chickenProducts[0].path,
          },
        ],
      },
      { sentence: [{ text: "2 slices wholegrain naan round bun" }] },
      { sentence: [{ text: "2 slices of aged gruyere cheese" }] },
      {
        sentence: [
          {
            text: "2 cups  zucchini rounds, very thinly sliced (about 1 small zucchini)",
          },
        ],
      },
      { sentence: [{ text: "1 cup red onion, thinly sliced" }] },
      { sentence: [{ text: "2 tbsp. " + canolaOil }] },
      { sentence: [{ text: "1/2 tsp salt" }] },
      { sentence: [{ text: "1/4 tsp freshly ground black pepper" }] },
      { sentence: [{ text: "6 tsp old-fashioned dijon mustard" }] },
      { sentence: [{ text: "3-4 slices cocktail tomatoes, thinly cut" }] },
      { sentence: [{ text: "2 cups baby arugula" }] },
    ],
    recipeSteps: [
      "Preheat barbecue to medium-high heat; place grilling basket on grill",
      "Spread dijon mustard on top and bottom of the naan",
      "Combine zucchini, red onion, oil, salt, and pepper in a bowl; stir well to coat. Transfer to grilling basket; cook, stirring often, for 4-5 minutes, or until golden and tender. Remove from heat; reduce barbecue to medium-low heat",
      "Arrange cheese evenly over naan bottom; fold chicken on the cheese, place grilled vegetables, and cover with naan top",
      "Carefully transfer sandwiches to grill; cover and cook for 2-3 minutes per side, or until cheese is melted and naan rounds have grill marks",
      "Remove from heat and open sandwich; add tomatoes and arugula. Serve immediately",
    ],
  },
  {
    filterProtein: "chicken",
    filterCategory: ["enter", "lunch", "snacks", "game"],
    filterProductType: ["Chipotle Seasoned Chicken Breast"],
    recipeName: "Chipotle Chicken MVP Bites",
    heroImage: mvpHeader,
    recipeLargeImage: mvpBitesLarge,
    recipeMediumImage: mvpBitesMedium,
    recipeSmallImage: mvpBitesSmall,
    recipeImageAlt: "Chipotle Chicken MVP Bites",
    recipeDescription: `Bring these to a game day lunch or family picnic and you'll be
      the fan favorite. Each empanada-inspired tortilla pocket is
      packed with diced Chipotle Seasoned Chicken Breast and filled
      with bold Southwestern flavors.`,
    Link: "recipes/chipotle-chicken-mvp-bites/",
    recipeIngredients: [
      {
        sentence: [
          { text: "2 cups " },
          {
            text: chipotleChicken,
            path: "/chicken/" + chickenProducts[2].path,
          },
          { text: ", finely diced" },
        ],
      },
      { sentence: [{ text: "10 flour tortillas" }] },
      { sentence: [{ text: "1 tbsp. olive oil" }] },
      { sentence: [{ text: "1 tbsp. yellow onion, diced" }] },
      { sentence: [{ text: "1/4 cup red bell pepper, diced" }] },
      { sentence: [{ text: "1/4 cup corn kernels, frozen" }] },
      { sentence: [{ text: "1 tsp. paprika" }] },
      { sentence: [{ text: "1 tsp. chili powder" }] },
      { sentence: [{ text: "1 tsp. cumin" }] },
      { sentence: [{ text: "1 tsp. garlic powder" }] },
      { sentence: [{ text: "1 tsp. dried oregano" }] },
      { sentence: [{ text: "Pinch of Kosher salt" }] },
      { sentence: [{ text: "Pinch of ground black pepper" }] },
      { sentence: [{ text: "1 lime, juiced" }] },
      { sentence: [{ text: "2 cups Mexican cheese blend" }] },
      { sentence: [{ text: "1 cup avocado, diced 1/2" }] },
      { sentence: [{ text: "1/4 cup cilantro, fresh & chopped" }] },
      { sentence: [{ text: "1 large egg, beaten" }] },
      { sentence: [{ text: "oil, for frying" }] },
      {
        sentence: [
          {
            text: "fresh cilantro, sour cream, guacamole & salsa, for serving",
          },
        ],
      },
    ],
    recipeSteps: [
      "In a large pan over medium heat, add and mix the olive oil, onion, red pepper, corn, paprika, chili powder, cumin, garlic powder, and dried oregano",
      "Add salt and pepper to taste. Add lime juice and cook the vegetables until soft, about 5 minutes",
      "Remove and put in a medium bowl",
      "Add the shredded chicken, cheese, avocado, and cilantro to the bowl and stir lightly until everything is mixed",
      "Cut a tortilla in half with a pizza cutter or knife",
      "Put a spoonful of the filling in the center of the tortilla half",
      "Fold each corner over the middle, forming a triangle",
      "Flip the tortilla triangle over so the seam is down",
      "Brush the inside of the open edge of the tortilla with egg wash and press with a fork to seal",
      "Repeat with the remaining tortillas and fillings",
      "In a deep pan, heat 2 inches of oil at 350°F (180°C)",
      "Once the oil is hot, place 2-3 tortilla triangles in the pan and cook on each side for 3 minutes, or until golden brown",
      "Drain the triangles on a rack",
      "Serve with cilantro, sour cream, guacamole, and salsa",
    ],
  },
  {
    filterProtein: "chicken",
    filterCategory: ["lunch", "picnic"],
    filterProductType: [OvenRoastedBreast],
    recipeName: "Chicken Banh Yum-Mi Sandwich",
    heroImage: banhHeader,
    recipeLargeImage: BanhYumMiLarge,
    recipeMediumImage: "",
    recipeSmallImage: "",
    recipeImageAlt: "Chicken Banh Yum-Mi Sandwich",
    recipeDescription: `These Vietnamese-style sandwiches made with Oven Roasted Chicken Breast 
    are great for individual meals, lunch platters, or party trays. 
    Try them tonight and thank us later.`,
    Link: "recipes/chicken-banh-yum-mi-sandwich/",
    recipeIngredients: [
      {
        sentence: [
          { text: "5 slices " },
          {
            text: saraleeOven,
            path: "/chicken/" + chickenProducts[0].path,
          },
        ],
      },
      { sentence: [{ text: "1 roll oven-toasted & split" }] },
      { sentence: [{ text: "1/8 tsp. sugar" }] },
      { sentence: [{ text: "2 tbsp. rice wine vinegar" }] },
      { sentence: [{ text: "2 tbsp. shredded carrots" }] },
      { sentence: [{ text: "1 tbsp. mayonnaise" }] },
      { sentence: [{ text: "1/8 tbsp. soy sauce" }] },
      { sentence: [{ text: "4-5 slices english cucumber, thinly sliced" }] },
      { sentence: [{ text: "4-6 strips red onion, thinly sliced" }] },
      { sentence: [{ text: "4-5 leaves cilantro" }] },
    ],
    recipeSteps: [
      "Stir sugar into rice wine vinegar in a small bowl until it is dissolved",
      "Add carrots and radishes; be sure they are submerged",
      "Let sit for at least 20 minutes (no more than an hour) until they soften",
      "Drain and set aside",
      "Mix mayonnaise and soy sauce together in a small bowl",
      "Spread soy mayonnaise on the bottom half of the roll",
      "Layer cucumbers, chicken breast, pickled carrot and radish mix, red onions, and cilantro",
      "Top with the remaining half of the roll",
    ],
  },
  {
    filterProtein: "chicken",
    filterCategory: ["snacks", "game", "enter"],
    filterProductType: [OvenRoastedBreast],
    recipeName: "Buffalo Chicken Winning Wontons",
    heroImage: wontonHeader,
    recipeLargeImage: wontonLarge,
    recipeMediumImage: "",
    recipeSmallImage: "",
    recipeImageAlt: "Buffalo Chicken Winning Wontons",
    recipeDescription: `Win your next tailgate with a platter of Winning Wontons loaded with Oven Roasted Chicken Breast. 
    They're seasoned with a Buffalo-style sauce making fans everywhere cheer for them.`,
    Link: "recipes/buffalo-chicken-winning-wontons/",
    recipeIngredients: [
      {
        sentence: [
          { text: "3 oz. " },

          {
            text: saraleeOven,
            path: "/chicken/" + chickenProducts[0].path,
          },
          { text: ", diced" },
        ],
      },
      { sentence: [{ text: "12 wonton wrappers" }] },
      { sentence: [{ text: "3 tbsp. blue cheese, finely minced" }] },
      { sentence: [{ text: "3 tbsp. carrots, finely minced" }] },
      { sentence: [{ text: "3 tbsp. celery, finely minced" }] },
      { sentence: [{ text: "1 1/2 tbsp. buffalo hot sauce" }] },
      {
        sentence: [{ text: "2 blue cheese dressing; plus, more for dipping" }],
      },
      { sentence: [{ text: "carrots & celery sticks" }] },
    ],
    recipeSteps: [
      "Trim the corners off the wontons to make them into circles",
      "Place each one into a small greased muffin tin to create a cup",
      "Combine chicken, blue cheese, carrots, celery, buffalo sauce, and blue cheese dressing in a small bowl",
      "Fill each wonton with about 2 teaspoons of filling",
      "Preheat the oven at 375°",
      "Place the muffin tin in the oven and bake for 20-30 minutes. Make sure not to burn the wontons, but bake long enough for the bottom to firm up",
      "Let them rest for 5-10 minutes, transfer to a tray and serve with blue cheese dressing while still hot",
      "Garnish with carrot and celery sticks",
    ],
  },
  {
    filterProtein: "chicken",
    filterCategory: ["lunch", "enter", "grill"],
    filterProductType: ["Chipotle Seasoned Chicken Breast"],
    recipeName: "Chipotle Chicken Grilled Bell Peppers",
    heroImage: bellHeader,
    recipeLargeImage: summerPeppersLarge,
    recipeMediumImage: "",
    recipeSmallImage: "",
    recipeImageAlt: "Chipotle Chicken Grilled Bell Peppers",
    recipeDescription: `Fire up the grill! Our smoky, savory, Chipotle Seasoned Chicken Breast fills these super-simple 
    stuffed bell peppers with Southwestern flavor—and no room for fillers or added hormones.`,
    Link: "recipes/chipotle-turkey-grilled-bell-peppers/",
    recipeIngredients: [
      {
        sentence: [
          { text: "12 oz. " },
          {
            text: chipChickenNormal,
            path: "/chicken/" + chickenProducts[2].path,
          },
          { text: ", sliced" },
        ],
      },
      { sentence: [{ text: "1 tbsp. olive oil" }] },
      { sentence: [{ text: "4 oz. pepper jack cheese, shredded" }] },
      { sentence: [{ text: "1 cup corn, frozen and thawed" }] },
      { sentence: [{ text: "1 cup black beans, canned and drained" }] },
      { sentence: [{ text: "black beans, canned and drained" }] },
      { sentence: [{ text: "2 sweet bell peppers, any color" }] },
      { sentence: [{ text: "1/4 cup " + canolaOil }] },
      { sentence: [{ text: "1/2 tsp. crushed red pepper" }] },
      { sentence: [{ text: "1 cup prepared guacamole" }] },
      { sentence: [{ text: "1 lime cut in 4 wedges and grilled" }] },
    ],
    recipeSteps: [
      "Pre-heat grill to medium-high. Brush grill grates with canola oil",
      "In a medium bowl, mix cheese, corn, black beans salt, and pepper",
      "Cut peppers in half lengthwise and remove the seeds",
      "Grill peppers cut side down on grill grates for 4 - 5 minutes or until slightly softened and edges are slightly charred",
      "Fill peppers with slices of turkey in each one",
      "Top with cheese, corn, and black beans mixture",
      "Drizzle with olive oil",
      "Return peppers to grill and cook for 5 - 6 minutes or until cheese is melted. If cheese is not melting, close grill cover for 2 minutes",
      "Remove from grill and add crushed pepper",
      "Serve with guacamole and a lime wedge",
    ],
  },
];
