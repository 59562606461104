import { turkeyRecipes } from "./turkeyRecipes";
import { hamRecipes } from "./hamRecipes";
import { chickenRecipes } from "./chickenRecipes";

export const product = [
  {
    id: 0,
    product: "Turkey",
    path: "turkey/",
    heroClassname: "turkeyHero",
    recipes: turkeyRecipes,
  },
  {
    id: 1,
    product: "Ham",
    path: "ham/",
    heroClassname: "hamHero",
    recipes: hamRecipes,
  },
  {
    id: 2,
    product: "Chicken",
    path: "chicken",
    heroClassname: "chickenHero",
    recipes: chickenRecipes,
  },
];
