import React from "react";
import { CTAButton } from "../Button/Button";
import "./HeroImage.scss";

const HeroImage = ({ title, subtitle, subtitleClassname, text, link, isMainTitle }) => {
  return (
    <div className="inner">
      <div className="inner--half">
        {isMainTitle ? (
          <h1 className="white">{title}</h1>
        ) : <h2 className="white">{title}</h2>}
        <p className={subtitleClassname}>
          <big>{subtitle}</big>
        </p>
        {text ? (
          <p>
            <CTAButton text={text} link={link}></CTAButton>
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default HeroImage;
