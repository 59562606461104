import React from "react";
import FeaturedProductHome from "../components/FeaturedProductHome/FeaturedProductHome";
import HeroHome from "../components/HeroHome/HeroHome";
import Layout from "../components/Layout";
import RecipeSolution from "../components/RecipeSolution/RecipeSolution";
import OurStory from "../components/OurStory/OurStory";
import FeaturedRecipesHome from "../components/FeaturedRecipesHome/FeaturedRecipesHome";
import Banners from "../components/Banners/Banners";
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

const Home = (props) => {
  const family = getImage(props.data.allFile.edges[0].node);
  return (
    <Layout title="Home" description="">
      <main>
        <HeroHome
          title={"SIMPLY DELICIOUS. DELICIOUSLY SIMPLE."}
          subtitle={
            "Make one trip to the deli counter and create sensational recipes your family will enjoy"
          }
          subtitleClassname={"maroon"}
          text={"Learn More"}
          link={"/recipes"}
        />
        <RecipeSolution />
        <FeaturedProductHome />
        <Banners />
        <FeaturedRecipesHome />
        <OurStory image={family} />
      </main>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: {}, name: { eq: "our-story" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              blurredOptions: { toFormat: WEBP }
              layout: FULL_WIDTH
              formats: WEBP
              webpOptions: { quality: 100 }
              quality: 100
            )
          }
        }
      }
    }
  }
`;
