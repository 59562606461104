import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import "./FeaturedRecipesHome.scss";
import { product as data } from "../../data/product";
import { CTAButton } from "../Button/Button";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const FeaturedRecipesHome = () => {
  const useWindowWidth = () => {
    const isBrowser = typeof window !== "undefined";
    const [width, setWidth] = useState(isBrowser ? window.innerWidth : 800);

    useEffect(() => {
      if (!isBrowser) return false;

      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    return width;
  };

  return (
    <Row className="featuredRecipeRow row" as={"section"}>
      <div className="featuredRecipeRow--inner">
        <Row
          as={"section"}
          md={useWindowWidth() > "799" ? 3 : 1}
          className="featuredRecipeRow--inner--innerRow"
        >
          <Link
            to="recipes/hardwood-smoked-turkey-fruit-salad/"
            className="featuredRecipeRow--inner--innerRow--featuredRecipeCard"
          >
            <h3 className="featuredRecipeRow--inner--innerRow--featuredRecipeCard--title">
              {data[0].recipes[0].recipeName}
            </h3>
            <p className="featuredRecipeRow--inner--innerRow--featuredRecipeCard--description">
              {data[0].recipes[0].recipeDescription}
            </p>
            <figure className="featuredRecipeRow--inner--innerRow--featuredRecipeCard--image">
              <StaticImage
                loading="lazy"
                src="../../images/recipes/turkey/THUMBNAIL-bts-smoked-chicken-salad.jpg"
                alt={data[0].recipes[0].recipeImageAlt}
              />
            </figure>
          </Link>
          <Link
            to="recipes/grilled-chicken-cordon-bleu-pizza/"
            className="featuredRecipeRow--inner--innerRow--featuredRecipeCard no-mobile"
          >
            <h3 className="featuredRecipeRow--inner--innerRow--featuredRecipeCard--title">
              {data[2].recipes[0].recipeName}
            </h3>
            <p className="featuredRecipeRow--inner--innerRow--featuredRecipeCard--description">
              {data[2].recipes[0].recipeDescription}
            </p>
            <figure className="featuredRecipeRow--inner--innerRow--featuredRecipeCard--image">
              <StaticImage
                loading="lazy"
                src="../../images/recipes/chicken/THUMBNAIL-summer-pizza.jpg"
                alt={data[2].recipes[0].recipeImageAlt}
              />
            </figure>
          </Link>
          <Link
            to="recipes/chipotle-chicken-mvp-bites/"
            className="featuredRecipeRow--inner--innerRow--featuredRecipeCard no-mobile"
          >
            <h3 className="featuredRecipeRow--inner--innerRow--featuredRecipeCard--title">
              {data[2].recipes[2].recipeName}
            </h3>
            <p className="featuredRecipeRow--inner--innerRow--featuredRecipeCard--description">
              {data[2].recipes[2].recipeDescription}
            </p>
            <figure className="featuredRecipeRow--inner--innerRow--featuredRecipeCard--image">
              <StaticImage
                loading="lazy"
                src="../../images/recipes/chicken/THUMBNAIL-mvp-bites.png"
                alt={data[2].recipes[2].recipeImageAlt}
              />
            </figure>
          </Link>
        </Row>
        <p>
          <CTAButton text="VIEW ALL RECIPES" link="/recipes"></CTAButton>
        </p>
      </div>
    </Row>
  );
};

export default FeaturedRecipesHome;
