import React from "react";
import { CTAButton } from "../Button/Button";
import "./RecipeSolution.scss";
import { StaticImage } from "gatsby-plugin-image";

const RecipeSolution = () => {
  return (
    <section className="Half_Table">
      <figure className="Half_Table__Image">
        <StaticImage
          loading="lazy"
          src="../../images/RECIPE-headers-ham-lunchbox-hero.jpg"
          alt="ham lunchbox"
        />
      </figure>
      <div className="Half_Table__Text">
        <div className="Half_Table__Text__Wrapper">
          <h2 className="Half_Table__Text__Wrapper__Title">RECIPE SOLUTIONS</h2>
          <p>
            <big className="Half_Table__Text__Wrapper__Par">
              Checkout our easy to make, easy to take recipes for your family!
            </big>
          </p>
          <p>
            <CTAButton link="/inspirations" text="LEARN MORE" />
          </p>
        </div>
      </div>
    </section>
  );
};

export default RecipeSolution;
