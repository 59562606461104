import React from "react";
import "./ProductBanner.scss";
import { CTAButton } from "../Button/Button";
import { Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
export const ProductBannerChicken = () => {
  return (
    <Row
      as={"section"}
      style={{ backgroundColor: "#fbb052" }}
      className="ProductsRight"
    >
      <div className="innerContainer justify-content-between">
        <Row className="ProductsRight__TextContainer">
          <h2 className="ProductsRight__TextContainer__Title">{"CHICKEN"}</h2>
          <div className="ProductsRight__TextContainer__Button">
            <CTAButton text={"SHOW ME"} link={"/chicken"} />
          </div>
        </Row>
        <Row className="ProductsRight__ImageContainer">
          <figure className="ProductsRight__ImageContainer__Image">
            <StaticImage
              loading="lazy"
              src={"../../images/chicken.jpg"}
              alt={"chicken"}
              width={481}
              className="ProductsRight__ImageContainer__Image"
            />
          </figure>
        </Row>
      </div>
    </Row>
  );
};

export const ProductBannerTurkey = () => {
  return (
    <Row
      as={"section"}
      style={{ backgroundColor: "#3b1d19" }}
      className="ProductsLeft"
    >
      <div className="innerContainer justify-content-between">
        <Row className="ProductsLeft__TextContainer">
          <h2 className="ProductsLeft__TextContainer__Title">{"TURKEY"}</h2>
          <div className="ProductsLeft__TextContainer__Button">
            <CTAButton text={"SHOW ME"} link={"/turkey"} />
          </div>
        </Row>
        <Row className="ProductsLeft__ImageContainer">
          <figure className="ProductsRight__ImageContainer__Image">
            <StaticImage
              width={481}
              loading="lazy"
              src="../../images/turkey.jpg"
              alt="Turkey"
              className="ProductsLeft__ImageContainer__Image"
            />
          </figure>
        </Row>
      </div>
    </Row>
  );
};
export const ProductBannerHam = () => {
  return (
    <Row
      as={"section"}
      style={{ backgroundColor: "#e31d3c" }}
      className="ProductsLeft"
    >
      <div className="innerContainer justify-content-between">
        <Row className="ProductsLeft__TextContainer">
          <h2 className="ProductsLeft__TextContainer__Title">{"HAM"}</h2>
          <div className="ProductsLeft__TextContainer__Button">
            <CTAButton text={"SHOW ME"} link={"/ham"} />
          </div>
        </Row>
        <Row className="ProductsLeft__ImageContainer">
          <figure className="ProductsRight__ImageContainer__Image">
            <StaticImage
              width={481}
              loading="lazy"
              src="../../images/ham.jpg"
              alt="Ham"
              className="ProductsLeft__ImageContainer__Image"
            />
          </figure>
        </Row>
      </div>
    </Row>
  );
};
