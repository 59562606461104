import React from "react";
import {
  ProductBannerTurkey,
  ProductBannerHam,
  ProductBannerChicken,
} from "../ProductBanner/ProductBanner";

const Banners = () => {
  return (
    <>
      <ProductBannerTurkey />
      <ProductBannerChicken />
      <ProductBannerHam />
    </>
  );
};

export default Banners;
